import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { Routes } from '@angular/router';

const redirectUnauthorizedToSignin = () => redirectUnauthorizedTo(['signin']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['']);

export const APP_ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToSignin, breadcrumb: null },
    loadComponent: () =>
      import('./core/components/layout/sidenav/sidenav.component').then(
        (mod) => mod.SidenavComponent
      ),
    children: [
      // 舊的admin基底導向為新版去除admin的版本
      { path: 'admin', redirectTo: '', pathMatch: 'prefix' },
      // 預設頁面為個案清單
      { path: '', redirectTo: 'cases', pathMatch: 'full' },
      {
        path: 'applications',
        loadChildren: () => import('./application/application.routes'),
      },
      {
        path: 'personnel-schedule',
        data: { breadcrumb: null },
        loadComponent: () =>
          import('./department/personnel-schedule/personnel-schedule.component').then(
            (mod) => mod.PersonnelScheduleComponent
          ),
      },
      { path: 'cases', loadChildren: () => import('./case/case.routes') },
      {
        path: 'physiological',
        loadChildren: () => import('./physiological/physiological.routes'),
      },
      {
        path: 'survey',
        loadChildren: () => import('./survey/survey.routes'),
      },
      {
        path: 'export',
        loadChildren: () => import('./export/export.routes'),
      },
      {
        path: 'downloads',
        loadChildren: () => import('./download/download.routes'),
      },
      {
        path: 'personnel',
        loadChildren: () => import('./personnel/personnel.routes'),
      },
      {
        path: 'manage',
        loadChildren: () => import('./manage/manage.routes'),
      },
      {
        path: 'docs',
        loadChildren: () => import('./docs/docs.routes'),
      },
      {
        path: 'contact',
        data: { breadcrumb: null },
        loadComponent: () =>
          import('./contact/contact.component').then((mod) => mod.ContactComponent),
      },
    ],
  },
  {
    path: '',
    loadComponent: () =>
      import('./core/components/layout/guest-layout/guest-layout.component').then(
        (mod) => mod.GuestLayoutComponent
      ),
    children: [
      { path: '', redirectTo: '/signin', pathMatch: 'full' },
      {
        path: 'signin',
        canActivate: [AuthGuard],
        data: { authGuardPipe: redirectLoggedInToHome },
        loadComponent: () =>
          import('./auth/signin/signin.component').then((mod) => mod.SigninComponent),
      },
      {
        path: 'services',
        loadChildren: () => import('./application/apply.routes'),
      },
      {
        path: 'guest',
        loadChildren: () => import('./guest/guest.routes'),
      },
    ],
  },
  {
    path: 'maria-daily-care-privacy',
    loadComponent: () =>
      import('./privacy/maria-daily-care-privacy/maria-daily-care-privacy.component').then(
        (mod) => mod.MariaDailyCarePrivacyComponent
      ),
  },
];
